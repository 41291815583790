import { MouseEventHandler } from "react";

export default function Pencil({
  size = 50,
  alt,
  onClick
}: {
  size?: number;
  alt?: string;
  onClick?: MouseEventHandler<SVGSVGElement>;
}) {
  return (
    <svg
      stroke="var(--thirty)"
      fill="var(--thirty)"
      strokeWidth="1"
      viewBox="0 0 24 24"
      height={`${size}px`}
      width={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      xlinkTitle={alt}
      onClick={onClick}
      style={onClick ? { cursor: "pointer" } : {}}
    >
      <g>
        <path
          d="M2.653 20.318 C8.536 15.385, 13.906 10.416, 17.007 8.377 M2.957 20.774 C7.502 16.489, 13.085 11.361, 16.162 7.405 M5.73 24.223 C9.445 18.758, 11.384 13.418, 15.643 7.306 M5.962 23.819 C8.294 18.789, 12.037 14.36, 16.101 7.723 M15.521 5.625 C16.297 5.425, 17.085 5.289, 19.319 4.887 M15.689 5.772 C16.926 5.324, 18.21 4.964, 19.222 4.696 M18.684 8.478 C18.96 7.453, 18.847 6.955, 19.039 4.82 M18.741 8.334 C18.808 7.362, 18.952 6.388, 19.199 4.738"
          stroke="var(--thirty)"
          strokeOpacity="1"
          strokeWidth="0.5"
          fill="none"
        ></path>
        <path
          d="M3.855 17.847 M2.834 17.19 C3.13 18.207, 2.914 18.938, 2.818 20.878 M3.082 17.22 C3.062 18.085, 3.078 18.889, 2.956 21.088 M2.976 21.007 C4.358 21.151, 5.38 21.082, 6.607 21.026 M2.977 21.092 C4.387 21.004, 6.014 20.996, 6.813 20.935 M7.094 20.714 C8.747 19.022, 12.212 16.031, 17.564 9.354 M6.654 21.142 C9.893 18.396, 12.564 15.244, 17.467 9.73 M17.995 9.678 C16.567 8.582, 14.921 6.948, 13.84 6.26 M17.926 9.904 C16.751 8.923, 15.793 8.055, 14.046 6.133 M13.861 5.997 C11.915 8.761, 8.87 10.682, 2.452 16.894 M14.082 6.391 C11.294 8.589, 9.144 11.753, 2.982 17.511 M21.472 7.545 M20.71 7.04 C21.965 6.512, 21.583 5.658, 20.156 5.93 M21.057 6.351 C20.664 6.03, 20.067 5.887, 21.292 4.828 M20.584 5.685 C19.8 4.863, 18.947 4.03, 18.474 3.171 M20.769 5.619 C19.912 4.789, 18.937 3.803, 18.415 3.292 M18.37 3.29 C17.159 1.945, 17.578 3.747, 17.267 2.87 M18.516 3.89 C16.99 2.618, 17.952 3.3, 17.497 2.864 M16.893 3.199 C16.367 3.961, 15.516 4.636, 15.075 5.132 M16.93 3.344 C16.272 3.943, 15.548 4.601, 15.067 5.169 M14.931 5.122 C16.612 6.805, 17.878 8.298, 19.006 8.734 M15.187 5.116 C16.224 6.223, 17.558 7.609, 18.824 8.812 M19 8.91 C19.434 8.326, 19.918 8.039, 20.6 6.969 M18.901 8.932 C19.423 8.396, 19.953 7.89, 20.755 7.076"
          fill="none"
        ></path>
      </g>
    </svg>
  );
}
