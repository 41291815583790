import { MouseEventHandler } from "react";

export default function CircleRough({
  size = 50,
  alt,
  onClick
}: {
  size?: number;
  alt?: string;
  onClick?: MouseEventHandler<SVGSVGElement>;
}) {
  return (
    <svg
      stroke="var(--thirty)"
      fill="var(--thirty)"
      strokeWidth="1"
      viewBox="0 0 100 100"
      height={`${size}px`}
      width={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      xlinkTitle={alt}
      onClick={onClick}
      style={onClick ? { cursor: "pointer" } : {}}
    >
      <g>
        <path
          d="M22.299 14.085 C22.299 14.085, 22.299 14.085, 22.299 14.085 M22.299 14.085 C22.299 14.085, 22.299 14.085, 22.299 14.085 M10.772 38.606 C17.882 30.147, 20.305 22.963, 37.533 7.038 M10.929 38.461 C15.115 31.986, 21.469 25.975, 35.721 10.88 M9.537 48.537 C24.947 37, 33.52 19.946, 47.747 4.209 M10.244 50.8 C23.016 37.139, 35.338 22.682, 47.632 5.73 M8.368 63.355 C17.991 49.431, 30.39 39.672, 56.764 10.234 M6.151 64.243 C17.757 50, 26.864 40.556, 54.016 7.194 M11.962 69.315 C30.086 49.029, 42.66 31.016, 64.986 12.147 M10.606 69.563 C26.153 51.287, 40.13 34.829, 63.636 8.649 M13.236 76.968 C30.467 59.147, 45.181 43.401, 69.78 11.744 M13.79 75.848 C38.252 49.715, 57.276 24.285, 69.357 10.924 M21.941 80.681 C30.438 66.886, 40.446 53.986, 78.376 13.503 M19.274 81.361 C43.092 53.693, 65.353 28.321, 77.299 15.383 M24.745 85.939 C34.899 71.32, 56.715 50.495, 82.156 17.779 M22.777 88.226 C44.212 62.073, 66.782 35.523, 80.939 19.657 M29.312 91.779 C46.689 76.873, 57.346 56.41, 85.284 24.37 M28.872 90.795 C46.302 69.67, 61.345 49.674, 87.709 24.324 M34.563 94.944 C60.365 64.759, 76 44.794, 96.036 26.558 M35.608 93.427 C44.39 80.053, 56.566 64.409, 94.707 26.865 M42.028 98.154 C63.636 73.949, 83.131 49.86, 97.902 39.2 M44.044 96.259 C60.919 76.64, 77.081 55.532, 96.54 37.156 M52.809 96.311 C64.866 79.875, 74.661 69.248, 96.158 44.606 M52.941 96.1 C65.438 80.132, 78.202 67.9, 98.259 45.549 M63.949 92.555 C74.696 75.873, 89.897 64.761, 96.437 51.478 M66.924 91.385 C71.905 83.562, 81.938 74.328, 99.059 53.354 M86.767 79.002 C87.245 78.036, 89.602 75.527, 90.714 74.625 M86.787 78.503 C87.958 77.13, 88.678 75.502, 91.327 73.72"
          stroke="red"
          strokeOpacity="0"
          strokeWidth="1"
          fill="none"
        ></path>
        <path
          d="M72.393 12.016 C80.297 14.668, 88.369 20.424, 92.627 27.363 C96.884 34.303, 98.49 45.553, 97.939 53.653 C97.387 61.753, 94.572 69.857, 89.317 75.963 C84.061 82.069, 74.725 87.264, 66.407 90.288 C58.089 93.312, 47.434 95.296, 39.41 94.106 C31.387 92.916, 24.208 88.876, 18.264 83.147 C12.32 77.417, 5.973 67.709, 3.744 59.729 C1.515 51.749, 1.778 42.898, 4.891 35.267 C8.004 27.636, 15.447 18.847, 22.423 13.942 C29.4 9.038, 38.127 5.887, 46.75 5.842 C55.372 5.798, 69.175 12.133, 74.156 13.673 C79.138 15.213, 76.899 14.557, 76.64 15.083 M55.269 6.635 C63.256 6.529, 71.575 10.311, 78.194 15.122 C84.813 19.933, 91.932 28.296, 94.982 35.502 C98.031 42.708, 98.12 50.372, 96.493 58.358 C94.866 66.344, 91.543 77.849, 85.22 83.418 C78.897 88.987, 66.99 90.623, 58.556 91.77 C50.122 92.916, 42.649 93.092, 34.616 90.299 C26.582 87.506, 15.589 81.355, 10.357 75.011 C5.125 68.668, 3.842 60.332, 3.225 52.237 C2.607 44.143, 2.707 33.295, 6.65 26.444 C10.594 19.594, 19.328 14.585, 26.886 11.132 C34.443 7.679, 47.705 6.739, 51.994 5.725 C56.282 4.711, 52.418 4.351, 52.615 5.047"
          fill="none"
        ></path>
      </g>
      <g>
        <path
          d="M22.416 8.886 C22.416 8.886, 22.416 8.886, 22.416 8.886 M22.416 8.886 C22.416 8.886, 22.416 8.886, 22.416 8.886 M9.182 31.183 C15.079 26.55, 23.27 17.531, 37.226 7.292 M8.903 34.369 C20.561 22.058, 27.729 12.574, 34.791 3.886 M6.566 44.723 C21.55 31.587, 33.224 9.745, 46.235 1.064 M8.646 48.832 C16.098 38.65, 23.357 29.074, 47.84 1.986 M6.801 57.435 C24.938 37.925, 45.052 14.814, 54.909 2.73 M4.49 60.684 C24.598 41.147, 42.294 19.904, 54.048 4.544 M11.012 66.309 C29.296 51.268, 42.576 28.884, 59.806 6.629 M8.394 65.882 C19.289 50.717, 34.374 36.186, 61.164 4.866 M11.869 72.944 C25.162 56.195, 39.049 46.546, 68.074 7.432 M13.555 75.063 C26.439 51.183, 44.26 32.706, 68.541 9.003 M15.377 83.2 C31.86 63.146, 45.227 45.303, 72.897 10.683 M14.795 80.567 C33.749 60.844, 51.167 37.54, 74.472 11.929 M18.586 86.554 C34.364 65.814, 47.017 50.705, 80.323 17.756 M20.027 85.717 C33.152 71.752, 46.821 54.01, 81.061 17.086 M27.506 88.272 C38.103 73.167, 54.29 56.513, 83.815 22.811 M25.82 89.464 C46.751 64.9, 70.449 38.31, 84.769 21.642 M32.131 94.715 C51.687 72.894, 69.481 52.607, 89.335 25.334 M32.82 91.612 C48.675 75.714, 66.967 55.742, 89.541 27.716 M41.83 98.582 C58.255 70.315, 84.164 40.249, 97.406 30.359 M40.565 96.89 C64.155 70.401, 85.619 45.23, 95.619 32.323 M51.25 95.587 C66.481 77.636, 85.498 52.798, 99.478 42.154 M46.975 96.316 C67.769 75.283, 87.128 52.496, 98.425 40.793 M56.904 97.643 C64.471 85.49, 74.285 76.649, 97.257 47.866 M57.043 97.83 C65.247 87.058, 75.339 76.936, 100.354 48.269 M68.313 99.166 C71.884 83.361, 81.308 72.673, 102.382 58.283 M67.145 95.937 C77.873 83.133, 88.801 69.836, 100.376 59.773"
          stroke="green"
          strokeOpacity="0"
          strokeWidth="1"
          fill="none"
        ></path>
        <path
          d="M22.405 8.899 C28.473 3.409, 39.058 0.942, 47.699 1.116 C56.341 1.29, 66.856 5.325, 74.253 9.943 C81.65 14.561, 88.163 20.929, 92.081 28.824 C95.999 36.72, 98.674 48.608, 97.76 57.316 C96.846 66.024, 91.959 74.542, 86.597 81.072 C81.235 87.601, 73.671 94.099, 65.588 96.495 C57.505 98.89, 46.65 97.922, 38.1 95.447 C29.55 92.973, 20.232 87.926, 14.289 81.647 C8.346 75.369, 3.635 66.073, 2.443 57.776 C1.251 49.48, 1.22 41.026, 7.134 31.868 C13.048 22.71, 30.551 7.555, 37.926 2.827 C45.302 -1.902, 51.431 2.473, 51.386 3.497 M69.081 8.225 C76.765 11.511, 83.757 18.935, 88.782 25.946 C93.807 32.956, 99.308 42.144, 99.233 50.288 C99.158 58.433, 93.123 67.374, 88.332 74.812 C83.541 82.25, 78.34 90.904, 70.486 94.915 C62.632 98.925, 49.557 100.302, 41.207 98.876 C32.857 97.45, 26.59 92.079, 20.386 86.359 C14.182 80.639, 6.319 72.885, 3.983 64.554 C1.648 56.222, 3.602 44.737, 6.372 36.369 C9.142 28.001, 14.407 19.965, 20.602 14.347 C26.797 8.729, 35.216 3.922, 43.541 2.661 C51.865 1.4, 66.236 6.323, 70.548 6.782 C74.861 7.241, 69.816 5.08, 69.417 5.416"
          fill="none"
        ></path>
      </g>
    </svg>
  );
}
